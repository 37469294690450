<template>
    <section>
        <div class="row mx-0 bg-white br-12 py-3">
            <div class="col border-right text-center text-general">
                <p class="f-18 f-500">{{ convertMoney(deudaGeneral.total) }}</p>
                <p class="f-14 mt-2">Crédito Total</p>
                <p class="mt-2">
                    <i class="icon-leechero-cap" />
                    {{ deudaGeneral.tenderosTotal }}
                </p>
            </div>
            <div class="col border-right text-center text-general">
                <p class="f-18 f-500">{{ convertMoney(deudaGeneral.vigente) }}</p>
                <p class="f-14">Deuda no vencida</p>
                <p class="mt-2">
                    <i class="icon-leechero-cap" />
                    {{ deudaGeneral.Tvigente }}
                </p>
            </div>
            <div class="col border-right text-center text-general">
                <p class="f-18 f-500">{{ convertMoney(deudaGeneral.menos30) }}</p>
                <p class="f-14 mt-2">Deuda a 30 días</p>
                <p class="mt-2">
                    <i class="icon-leechero-cap" />
                    {{ deudaGeneral.Tmenos30 }}
                </p>
            </div>
            <div class="col border-right text-center text-general">
                <p class="f-18 f-500">{{ convertMoney(deudaGeneral.menos60) }}</p>
                <p class="f-14 mt-2">Deuda a 60 días</p>
                <p class="mt-2">
                    <i class="icon-leechero-cap" />
                    {{ deudaGeneral.Tmenos60 }}
                </p>
            </div>
            <div class="col border-right text-center text-general">
                <p class="f-18 f-500">{{ convertMoney(deudaGeneral.menos90) }}</p>
                <p class="f-14 mt-2">Deuda a 90 días</p>
                <p class="mt-2">
                    <i class="icon-leechero-cap" />
                    {{ deudaGeneral.Tmenos90 }}
                </p>
            </div>
            <div class="col text-center text-general">
                <p class="f-18 f-500">{{ convertMoney(deudaGeneral.mas90) }}</p>
                <p class="f-14 mt-2">Deuda a + 90 días</p>
                <p class="mt-2">
                    <i class="icon-leechero-cap" />
                    {{ deudaGeneral.Tmas90 }}
                </p>
            </div>
        </div>
        <div class="row mx-0 mt-3">
            <div class="col-12 px-0">
                <tabla-general
                v-loading="loading"
                alto="calc(100vh - 467px)"
                :mostrar-buscador="true"
                :data="tableData"
                :sin-datos="`No tienes ${$config.vendedor} deudores`"
                >
                    <template slot="cabecera-izquierda">
                        <div class="col-auto f-17 f-500 text-general">
                            {{ $config.vendedor }} con deuda
                        </div>
                    </template>
                    <el-table-column prop="nombre" fixed :label="$config.vendedor" sortable width="230" class-name="f-14 text-general f-600">
                        <template slot-scope="scope">
                            <p class="cr-pointer" @click="verTienda(scope.row.id_tienda)">
                                {{ scope.row.nombre }}
                            </p>
                        </template>
                    </el-table-column>
                    <el-table-column prop="cedis_nombre" label="CEDIS" width="230" class-name="f-14 text-general">
                        <template slot-scope="scope">
                            <p class="cr-pointer" @click="verTienda(scope.row.id_tienda)">
                                {{ scope.row.cedis_nombre }}
                            </p>
                        </template>
                    </el-table-column>
                    <el-table-column prop="cupo_credito" label="Cupo del crédito" width="230" class-name="f-14 text-general">
                        <template slot-scope="scope">
                            <p class="cr-pointer">
                                {{ scope.row.cupo_credito == 0 ? 'Ilimitado' : separadorNumeroDecimales(scope.row.cupo_credito) }}
                            </p>
                        </template>
                    </el-table-column>
                    <el-table-column prop="valor_pendiente" label="Deuda Total" sortable min-width="150" class-name="f-14 text-general">
                        <template slot-scope="scope">
                            {{ separadorNumero(scope.row.valor_pendiente) }}
                        </template>
                    </el-table-column>
                    <el-table-column prop="deuda_vigente" label="Deuda no vencida" sortable min-width="170" class-name="f-14 text-general">
                        <template slot-scope="scope">
                            {{ separadorNumero(scope.row.deuda_vigente) }}
                        </template>
                    </el-table-column>
                    <el-table-column prop="deuda_30" label="Deuda a 30 Días" sortable min-width="160" class-name="f-14 text-general">
                        <template slot-scope="scope">
                            {{ separadorNumero(scope.row.deuda_30) }}
                        </template>
                    </el-table-column>
                    <el-table-column prop="deuda_60" label="Deuda a 60 Días" sortable min-width="160" class-name="f-14 text-general">
                        <template slot-scope="scope">
                            {{ separadorNumero(scope.row.deuda_60) }}
                        </template>
                    </el-table-column>
                    <el-table-column prop="deuda_90" label="Deuda a 90 Días" sortable min-width="160" class-name="f-14 text-general">
                        <template slot-scope="scope">
                            {{ separadorNumero(scope.row.deuda_90) }}
                        </template>
                    </el-table-column>
                    <el-table-column prop="deuda_mas_90" label="Deuda a +90 Días" sortable min-width="170" class-name="f-14 text-general">
                        <template slot-scope="scope">
                            {{ separadorNumero(scope.row.deuda_mas_90) }}
                        </template>
                    </el-table-column>
                    <el-table-column prop="ultimo_pedido" label="Ultimo Pedido" sortable min-width="150" class-name="f-14 text-general">
                        <template slot-scope="scope">
                            <el-tooltip class="item" effect="light" :content="'ID:' + scope.row.ultimo_pedido_id" placement="right">
                                <span>{{ scope.row.ultimo_pedido | helper-fecha('YYYY-MM-DD') }}</span>
                            </el-tooltip>
                        </template>
                    </el-table-column>
                    <el-table-column prop="ultimo_pago" label="Ultimo Pago" sortable min-width="150" class-name="f-14 text-general">
                        <template slot-scope="scope">
                            <span>{{ scope.row.ultimo_pago | helper-fecha('YYYY-MM-DD') }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="fecha_menor_cuota" label="Próximo pago" sortable min-width="150" class-name="f-14 text-general">
                        <template slot-scope="scope">
                            <span v-if="scope.row.vencido" class="text-general-red">{{ scope.row.fecha_menor_cuota }}</span>
                            <span v-else>{{ scope.row.fecha_menor_cuota }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column label="" fixed="right" width="80">
                        <template v-if="$can('boton_tesoreria_leecheros_chat')" slot-scope="scope">
                            <el-tooltip class="item" effect="light" :content="`Hablar con el ${$config.vendedor}`" placement="bottom">
                                <div class="rounded-circle br-12 d-middle-center cr-pointer" style="width:32px;height:32px;" @click="AbrirComentarios(scope.row.created_by_tienda)">
                                    <i class="icon-message-text-outline hover-icons f-20" />
                                </div>
                            </el-tooltip>
                        </template>
                    </el-table-column>
                </tabla-general>
            </div>
        </div>
        <modalChat ref="modalChat" />
    </section>
</template>

<script>
import Creditos from '~/services/almacen/creditos'
import {mapGetters} from 'vuex'

export default {
    data(){
        return {
            loading: false,
            tableData: [
                // { nombre: 'OXXO', total: '$ 534.000', ultimo_pago: '01 Jul. 2018', ultimo_pedido: '01 Jul. 2018', proximo_pago: '05 Jul. 2008' },
            ],
            deudaGeneral:{}
        }
    },
    computed:{
        ...mapGetters({
            id_cedis:'cedis/id_cedis',
            id_moneda:'cedis/id_moneda',
        }),
    },
    watch:{
        id_cedis(val){
            this.listarTabla()
        },
        id_moneda(val){
            this.listarTabla()
        }
    },
    mounted(){
        this.listarTabla()
    },
    methods: {
        async listarTabla(){
            try {
                this.loading = true
                const params = {
                    id_cedis: this.id_cedis,
                    id_moneda: this.id_moneda,
                    tendero: 1
                }
                const {data} = await Creditos.listarCreditosTabTenderos(params)
                this.tableData = data.creditos
                this.deudaGeneral = data.deuda_general
                this.loading = false
            } catch (e){
                this.loading = false
                this.error_catch(e)
            }
        },
        AbrirComentarios(id_user_admin_created){
            this.$refs.modalChat.toggle(id_user_admin_created, 6) // 6 = tipo credito pedido -admin
        },
        verTienda(id_tienda){
            this.$router.push({name: 'admin.tiendas.produccion.creditos.tenderoVer', params: { id_tienda: id_tienda }})
        }
    }
}
</script>
